import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyBzgDX4dU5cEp2HGQXnypeBwlsGZCTK8lw",
    authDomain: "encore-music-education.firebaseapp.com",
    databaseURL: "https://encore-music-education.firebaseio.com",
    projectId: "encore-music-education",
    storageBucket: "encore-music-education.appspot.com",
    messagingSenderId: "155318554440",
    appId: "1:155318554440:web:5e4e106073bd0c0a13ed7d",
    measurementId: "G-BGWL7DSNL4"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
const storage = firebase.storage();

export {
    db,
    auth,
    functions,
    storage
}