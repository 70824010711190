import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueScrollTo from 'vue-scrollto'
import VueMq from 'vue-mq'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css';

import App from './App.vue'
import router from './router'
import store from './store'
import './assets/scss/app.scss'
import { firestorePlugin } from 'vuefire';
import { auth, functions } from '@/firebase'

if (window.location.hostname == 'localhost') {
	functions.useEmulator('localhost', 5001);
}

Vue.config.productionTip = false

Vue.use(VueToast);

Vue.use(VueScrollTo);

Vue.use(VueMeta, {
	refreshOnceOnNavigation: true
})

Vue.use(VueMq, {
	breakpoints: {
		xs: 450,
		sm: 700,
		md: 900,
		tab: 1200,
		lg: Infinity
	}
})

Vue.use(firestorePlugin);

let app;

auth.onAuthStateChanged(async user => {
	if (user) {
		await store.dispatch('user/setupUserBinds', user.uid)
	} else {
		await store.dispatch('user/unbindUserBinds')
	}

	let sessionTimeout = null;
	if (user === null) {
		// User is logged out.
		// Clear the session timeout.
		sessionTimeout && clearTimeout(sessionTimeout);
		sessionTimeout = null;
	} else {
		// User is logged in.
		// Fetch the decoded ID token and create a session timeout which signs the user out.
		user.getIdTokenResult().then((idTokenResult) => {
			// Make sure all the times are in milliseconds!
			const authTime = idTokenResult.claims.auth_time * 1000;
			const sessionDuration = 3.6e6;
			const millisecondsUntilExpiration = sessionDuration - (Date.now() - authTime);
			sessionTimeout = setTimeout(() => {
				auth.signOut();
				app.$toast.info("You have been automatically logged out due to your session expiring.", 5000);
			}, millisecondsUntilExpiration);
		});
	}

	if (!app) {
		app = new Vue({
			router,
			store,
			render: h => h(App)
		}).$mount('#app')
	}
})