<template>
	<div id="app" :class="{ fullscreen: fullscreenRequired }">
		<div id="nav" v-if="navRequired">
			<mq-layout :mq="['xs','sm','md', 'tab']">
				<div class="menu-button" @click="menuButtonClicked">
					<svg viewBox="0 0 50 50">
						<path d="M 5 5 L 45 45 M 5 45 L 45 5" v-if="showMenu"/>
						<path d="M 3 10 L 47 10 M 3 25 L 47 25 M 3 40 L 47 40" v-else/>
					</svg>
				</div>
			</mq-layout>

			<mq-layout :mq="['lg']">
				<img src="@/assets/images/EME_logo.svg" @click="$router.push('/')">
			</mq-layout>
			
			<div class="nav-right">
				<div class="nav-content-container" :class="{ closed: !showMenu }">
					<router-link @click.native="menuButtonClicked" to="/for-teachers">For Teachers</router-link>
					<router-link @click.native="menuButtonClicked" to="/courses">Courses</router-link>
					<router-link @click.native="menuButtonClicked" to="/videos">Video Library</router-link>
					<router-link @click.native="menuButtonClicked" to="/star">Encore Star</router-link>
					<router-link @click.native="menuButtonClicked" to="/teachers">Find a Teacher</router-link>
					<router-link @click.native="menuButtonClicked" to="/musicwiz">Music Wiz App</router-link>
					<router-link @click.native="menuButtonClicked" to="/smart">SMART</router-link>
					<router-link @click.native="menuButtonClicked" to="/order">Order</router-link>
					<mq-layout :mq="['xs', 'sm', 'md', 'tab']" style="display: flex">
						<router-link @click.native="menuButtonClicked" :to="{name: 'login', params: { redirect: $route.name}}" v-if="!isLoggedIn">Teacher Login</router-link>
						<router-link @click.native="menuButtonClicked" to="/logout" v-else>Logout</router-link>
					</mq-layout>
				</div>

				<mq-layout :mq="['lg']">
					<div class="teacher-login-container">
						<router-link class="login" :to="{name: 'login', params: { redirect: getRedirect()}}" v-if="!isLoggedIn">Teacher Login</router-link>
						<router-link class="login" to="/logout" v-else>Logout</router-link>
					</div>
				</mq-layout>
			</div>
		</div>

		<mq-layout :mq="['xs','sm','md', 'tab']" v-if="navRequired">
			<div class="small-screen-encore">
				<img src="@/assets/images/EME_logo.svg" @click="$router.push('/')">
			</div>
		</mq-layout>

		<router-view/>
		<div id="content" class="home-content blue-box" v-if="navRequired && !fullscreenRequired">
			<p class="tagline">Smart Teaching ~ Faster Learning</p>
		</div>
		<div id="footer" v-if="navRequired && !fullscreenRequired">
			<div class="footer-header">
				<div class="footer-column">
					<p class="footer-title">Connect with Us</p>
					<div class="footer-socials">
						<a href="https://www.facebook.com/EncoreMusicEducation" target="_blank" class="fa fa-facebook"></a>
						<a href="https://www.instagram.com/encoremusiceducation/" target="_blank" class="fa fa-instagram"></a>
					</div>
				</div>

				<div class="footer-column">
					<p class="footer-title">Links</p>
					<p class="footer-link" @click="$router.push('/about')">About</p>
					<p class="footer-link" @click="$router.push('/contact')">Contact Us</p>
					<br>
					<p class="footer-link" @click="$router.push('/smart/0')">Encore SMART Teaching</p>
					<p class="footer-link" @click="$router.push('/smart/1')">The Encore Difference</p>
					<p class="footer-link" @click="$router.push('/smart/2')">Encore &amp; Exams</p>
					<br>
					<p class="footer-link" @click="$router.push('/courses/journal')">Music Journal</p>
					<p class="footer-link" @click="$router.push('/downloads')">Free Downloads</p>
					<br>
					<p class="footer-link" @click="$router.push({ name: 'videos'})">Video Library</p>
				</div>

				<div class="footer-column">
					<p class="footer-title">Encore Music Education</p>
					<p class="footer-subtitle">Published and distributed in Australia by Accent Publishing Pty Ltd.</p>
					<p class="footer-subtitle">© Copyright 2021 Encore Music Education Pty Ltd. All Rights Reserved.</p>
					<p class="footer-subtitle">ACN 131 739 154</p>
				</div>
			</div>

			<div class="footer-logo-container">
				<img src="@/assets/images/AccentEncore.svg">
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			showMenu: false
		}
	},
	methods: {
		menuButtonClicked() {
			this.showMenu = !this.showMenu
		},
		getRedirect() {
			const arr = ["videos", "teachingNotes", "marketingMaterials"];

			if (arr.includes(this.$route.name)) {
				return this.$route.name
			} else {
				return "forTeachers"
			}
		}
	},
	computed: {
		navRequired() {
			return this.$route.name != "login" && this.$route.name != "watch" && this.$route.name != "reset"
		},
		fullscreenRequired() {
			return this.$route.name == "teachers"
		},
		...mapGetters('user', ['isLoggedIn'])
	},
	watch: {
        $route: {
            immediate: true,
            handler(to) {
                document.title = 'Encore Music Education' + (to.meta.title ? ' | ' + to.meta.title : '');
            }
        },
    }
}
</script>