<template>
	<div class="home">
		<div id="content" class="home-content blue-box curved" style="--accent: #00a7e5">
			<svg width="500" height="150" viewBox="0 0 500 151" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M0,150 L0,26 Q250,-16 500,26 L500,150 Q250,110 0,150Z" fill="#00a7e5"/>

				<clipPath id="sweepClip">
					<path d="M0,150 L0,26 Q250,-16 500,26 L500,150 Q250,110 0,150Z"/>
				</clipPath>
				<clipPath id="halfClip" clip-path="url(#sweepClip)">
					<path d="M 370,9 q 10 62.5, 0 122 L 500, 300 L500,0 L250,7"/>
				</clipPath>

				<clipPath id="sweepClip2">
					<path d="M0,150 L0,26 Q250,-16 500,26 L500,150 Q250,110 0,150Z"/>
				</clipPath>
				<clipPath id="halfClip2" clip-path="url(#sweepClip2)">
					<path d="M 130,9 q -10 62.5, 0 122 L 000, 300 L0,0 L160,9"/>
				</clipPath>

				<pattern id="img" patternUnits="userSpaceOnUse" width="265" height="200" x="-20" y="0">
					<image :href="bannerImage"
							x="0" y="0" width="265" height="200"
							preserveAspectRatio="xMinYMin slice"/>
				</pattern>

				<text class="svg-title" x="250" y="50" fill="white" text-anchor="middle">Smart Teaching ~</text>
				<text class="svg-title" x="250" y="70" fill="white" text-anchor="middle">~ Faster Learning</text>

				<text class="svg-subtitle" id="statement-rotate" x="250" y="95" fill="white" text-anchor="middle" width="100">Teach beyond the page with creativity and confidence.</text>

				<rect id="imgFill" x="240" y="0" width="260" height="200" fill="url(#img)" clip-path="url(#halfClip)"/>
				<rect id="imgFill2" x="00" y="0" width="260" height="200" fill="url(#img)" clip-path="url(#halfClip2)"/>

				<path d="M0,28 L0,24 Q250,-15 500,24 L500,28 Q250,-12 0,28Z" fill="#8fd7f2"/>
				<path d="M0,151 L0,147 Q250,108 500,147 L500,151 Q250,111 0,151Z" fill="#8fd7f2"/>

				<path d="M 370,12 q 10 62.5, 0 122" stroke="#8fd7f2" fill="none" stroke-width="2"/>
				<path d="M 130,12 q -10 62.5, 0 122" stroke="#8fd7f2" fill="none" stroke-width="2"/>
			</svg>

			<mq-layout :mq="['xs','sm','md', 'tab']" class="content-wide centered-banner">
				<p class="banner-title">Smart Teaching ~</p>
				<p class="banner-title">~ Faster Learning</p>
				<p class="subtitle-small">Teach beyond the page with creativity and confidence.</p>
            </mq-layout>

            <mq-layout :mq="['lg']" class="content-left home-page">
				<div class="home-spacer"></div>
            </mq-layout>
		</div>

		<div id="content" class="home-content home-page">
			<!-- <div class="content-wide">
				<p class="heading handwriting left">Follow Us</p>
				<p class="info-bold spacing">Our latest teacher workshops and events are posted to our <a href="https://www.facebook.com/EncoreMusicEducation" target="_blank" class="info-link">Facebook</a> and <a href="https://www.instagram.com/encoremusiceducation/" target="_blank" class="info-link">Instagram</a> pages. Please follow us to stay up-to-date with all Encore news and events.

				<p class="heading handwriting">Explore Our Courses</p>

				<CourseSelect @select="$router.push(`/courses/${$event}`)"></CourseSelect>
			</div> -->

			<div class="home-box-container">
				<div class="home-box" style="--box-color: #00a7e5">
					<div class="box-title">
						<p>Our Approach</p>
					</div>

					<div class="box-content">
						<div class="words">
							<p class="info">Encore integrates a multi-sensory approach with a dynamic rhythmic focus, whole-pattern learning and multiple learning layers.</p>
							<p class="info">Our passion is to develop versatile musicians who inherently understand what they play, hear, read, and sing. We partner with teachers to provide extensive resources, expertise and a breadth of knowledge.</p>
							<p class="info">We are not just a method book system. Our all-encompassing approach can be applied to every level of teaching and learning. Join our community of imaginative teachers today and discover the benefits of <span style="font-style: italic">Smart Teaching ~ Faster Learning</span>.</p>
						</div>

						<button class="button-round" @click="$router.push('/smart/0')">Learn More</button>
						<button class="button-round" @click="$router.push('/smart/2')">Encore &amp; Exams</button>
					</div>
				</div>
				
				<div class="home-box" style="--box-color: #00b25a">
					<div class="box-title">
						<p>Teacher Resources</p>
					</div>

					<div class="box-content">
						<div class="words">
							<p class="info">Encore Teacher Resources provide you with the knowledge and confidence to teach beyond the page.</p>

							<p class="box-heading">Video Library</p>
							<p class="info">Our library of online videos gives teachers hands-on experience of how to apply <span style="font-style: italic">Encore Smart Teaching</span> in lessons. High-end teaching skills are developed within a dynamic and creative learning environment.</p>
						
							
							<p class="box-heading">Teacher Guides</p>
							<p class="info">Our comprehensive Teacher Guides feature detailed lesson plans, teaching sequences and creative extensions. We give you the <span style="font-style: italic">Smart Teaching</span> process for every piece, every lesson.</p>
						</div>
			
						<button class="button-round" @click="$router.push('/downloads')">Free Downloads</button>
						<button class="button-round" @click="$router.push('/videos')">Video Library</button>
					</div>
				</div>

				<div class="home-box" style="--box-color: #f78e1f">
					<div class="box-title">
						<p>Student Courses</p>
					</div>

					<div class="box-content">
						<div class="words">
							<p class="course-label" style="background-color: #f78e1f">Kinder Beat <span id='reg'>&reg;</span></p>
							<p class="course-label" style="background-color: #28bdb2">Encore on Keys</p>
							<p class="course-label" style="background-color: #ef3a41">Encore on Strings</p>
							<p class="course-label" style="background-color: #453f9a">Theory Wiz</p>
							<br>

							<p class="info">Encore's student courses accommodate all learning styles and abilities. We cover Early Childhood, Piano, Strings and Music Theory.</p>
							<p class="info">Our early childhood and instrumental courses come with access to our exceptional backing tracks. Download the Encore Music Wiz app today and have your students grooving along from day one.</p>
							<p class="info">We also provide a range of other teaching aids including our Music Practice Journal, Encore Smart Mats and more.</p>
						</div>

						<button class="button-round" @click="$router.push('/courses')">Browse Courses</button>
						<button class="button-round" @click="$router.push('/courses/journal')">Music Journal</button>
					</div>
				</div>
			</div>
		</div>

		<!-- <div id="content" class="home-content">
			<div class="content-left">
				<p class="heading handwriting left">Encore SMART Teaching</p>

				<p class="info-bold">
					Encore SMART Teaching combines contemporary flair, traditional fundamentals and an engaging rhythmic energy.
				</p>

				<p class="info">
					<ul>
						<li>Encore SMART Teaching simplifies many aspects of learning music.</li>
						<li>It embraces a multi-sensory approach that is thorough, musically engaging and accommodates all learning styles.</li>
						<li>World-class backing tracks and recordings support a dynamic approach, a diverse range of styles and multiple musical layers.</li>
						<li>Whole-pattern learning ensures that students learn complete musical units in a sequential and logical framework.</li>
						<li>Rhythmic perception, aural awareness and technical foundations are embedded from the outset.</li>
						<li>Progress is immediate, consistent and accurate.</li>
					</ul>
				</p>

				<p class="info">
					We have invested in our beliefs and worked with industry professionals to create beautiful artwork, captivating characters, and recordings of the highest quality. Our course books project a warmth and personality guaranteed to motivate every teacher, parent and child.
				</p>

				<br>

				<button class="button-round" @click="$router.push('/smart/0')">Learn More</button>
				<button class="button-round" @click="$router.push('/smart/1')">The Encore Difference</button>
				<button class="button-round" @click="$router.push('/smart/2')">Encore &amp; Exams</button>
			</div>

			<div class="content-right">
				<img src="@/assets/images/EME-tree-transparent.png">
			</div>
		</div> -->
	</div>
</template>

<script>
export default {
	data() {
		return {
			bannerImages: [
				"1.jpg",
				"2.jpg",
				"3.jpg",
				"4.jpg",
				"5.jpg",
			],
			bannerImageId: 0,
			statements: [
				"Our world-renowned approach helps teachers develop multi-skilled and versatile musicians.",
				"Teach beyond the page with creativity and confidence.",
				"Discover our award-winning student books and extensive resources for teachers."
			],
			statementId: 0
		}
	},
	computed: {
		bannerImage() {
			return require(`@/assets/images/home/${this.bannerImages[this.bannerImageId]}`)
		}
	},
	mounted() {
		window.setInterval(() => {
			this.bannerImageId = (this.bannerImageId + 1) % this.bannerImages.length;
		}, 5000);

		window.setInterval(() => {
			this.statementId = (this.bannerImageId + 1) % this.statements.length;
		}, 7000);
	}
}
</script>