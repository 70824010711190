import { db } from '@/firebase'

const state = () => ({
    uid: null,
    info: null,
    isAdmin: false
})

const mutations = {
    setUID(state, val) {
        state.uid = val
    },
    setIsAdmin(state, val) {
        state.isAdmin = val
    }
}

const getters = {
    isLoggedIn(state) {
        return !!state.uid
    }
}

const actions = {
    async setupUserBinds({ commit, dispatch }, uid) {
        await commit('setUID', uid)

        let admin = await db.collection('admins').doc(uid).get()

        if (admin) {
            if (admin.data() && admin.data().isAdmin) {
                await commit('setIsAdmin', true)
            }
        }

        await dispatch('teachers/recordTeacherLoginDate', null, { root: true });
    },
    async unbindUserBinds({ commit }) {
        await commit('setUID', null)
        await commit('setIsAdmin', false)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}