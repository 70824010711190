import { db } from '@/firebase'
import { firestoreAction } from 'vuexfire'

const state = () => ({
    resources: [],
    collections: [],
    subCollections: [],
    resourceSettings: "",
    accessGroups: []
})

const getters = {
    videoURL(state) {
        if (state.resourceSettings) {
            return state.resourceSettings.videoURL;
        } else {
            return null;
        }
    },
    videoCaption(state) {
        if (state.resourceSettings) {
            return state.resourceSettings.videoCaption;
        } else {
            return null;
        }
    }
}

const actions = {
    async setupResourceBinds({ dispatch }) {
        await dispatch('bindResources')
        await dispatch('bindSubCollections')
        await dispatch('bindCollections')
        await dispatch('bindAccessGroups')
        await dispatch('bindSettings')
    },
    bindResources: firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef('resources', db.collection('resources'))
    }),
    bindSettings: firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef('resourceSettings', db.collection('settings').doc('resources'))
    }),
    bindAccessGroups: firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef('accessGroups', db.collection('settings').doc('resources').collection('accessGroups'))
    }),
    bindCollections: firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef('collections', db.collection('resourceCollections'))
    }),
    bindSubCollections: firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef('subCollections', db.collection('subCollections'))
    })
}

export default {
    namespaced: true,
    state,
    getters,
    actions
}